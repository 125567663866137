import "devextreme/dist/css/dx.light.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/style.css";
import icons from "./icons";

for (const key in icons) {
  Vue.component(key, icons[key]);
}

Vue.component("loading", () => import("@/components/Loading.vue"));

Vue.use(require("vue-moment"));

// Breadcrumbs
import VueBreadcrumbs from "vue-2-breadcrumbs";
Vue.use(VueBreadcrumbs, {
  template: `
  <nav v-if="$breadcrumbs.length" aria-label="breadcrumb" class="flex items-center text-xs list-none my-5">
  <li v-for="(crumb, key) in $breadcrumbs.filter(c => c.meta.breadcrumb != 'لوحة التحكم')" v-if="crumb.meta.breadcrumb" :key="key" class="breadcrumb-item active" aria-current="page">
    <router-link :to="{ path: getPath(crumb) }">
      <span class="text-secondary font-semibold">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</span> <span class="mx-2" v-if="key + 1 != $breadcrumbs.filter(c => c.meta.breadcrumb != 'لوحة التحكم').length">/</span>
    </router-link>
  </li>
  </nav>
  `,
});

// charts
import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

// Websockets
import socket from "./socket";
Vue.prototype.$socket = socket;

// notifications
import Toasted from "vue-toasted";

Vue.use(Toasted);

// alerts
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  confirmButtonColor: "#16a34a",
  cancelButtonColor: "#ef4444",
};

// v-select
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import "vue-select/dist/vue-select.css";

Vue.use(VueSweetalert2, options);

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/App.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/reports/schools",
        name: "reports-schools",
        component: () => import("@/views/reports/Schools.vue"),
      },
    ],
  },
  {
    path: "",
    component: () => import("@/layouts/Dashboard.vue"),
    meta: {
      breadcrumb: "لوحة التحكم",
    },
    children: [
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/dashboard/Notifications.vue"),
        meta: {
          permissions: ["*"],
          exclude: ["manufacturer", "observer"],
        },
      },
      // {
      //   path: "/delivered-now",
      //   name: "delivered-now",
      //   component: () => import("@/views/dashboard/DeliveredNow.vue"),
      //   meta: {
      //     permissions: ["observer"],
      //     exclude: [],
      //   },
      // },
      {
        path: "/delivery-details",
        name: "delivery-details",
        component: () => import("@/views/dashboard/DeliveryDetails.vue"),
        meta: {
          permissions: ["observer"],
          exclude: [],
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/Home.vue"),
        meta: {
          permissions: ["think", "superadmin", "observer", "governer"],
          exclude: ["observer"],
        },
      },
      {
        path: "/stock-report",
        name: "stock-report",
        component: () => import("@/views/dashboard/StockReport.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
        },
      },
      {
        path: "/management",
        name: "management",
        component: () => import("@/views/dashboard/users/Management.vue"),
        meta: {
          permissions: ["admin"],
          exclude: [],
        },
      },
      {
        path: "/school",
        name: "school",
        component: () => import("@/views/dashboard/users/School.vue"),
        meta: {
          permissions: ["school"],
          exclude: [],
        },
      },
      {
        path: "/history",
        name: "history",
        component: () => import("@/views/dashboard/History.vue"),
        meta: {
          permissions: ["*"],
          exclude: ["manufacturer", "observer"],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/dashboard/users/Users.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
          breadcrumb: {
            label: "المستخدمين",
          },
        },
      },
      {
        path: "/distributor",
        name: "distributor",
        component: () => import("@/views/dashboard/users/Distributor.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
        },
      },
      {
        path: "/manufaturers",
        name: "manufaturers",
        component: () => import("@/views/dashboard/users/Manufacturers.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
        },
      },
      {
        path: "/users/manage",
        name: "users-manage",
        component: () => import("@/views/dashboard/users/ManageSessions.vue"),
        meta: {
          permissions: ["think", "superadmin", "admin", "governer"],
          exclude: [],
          breadcrumb: {
            label: "إدارة حسابات المستخدمين",
            parent: "users",
          },
        },
      },
      {
        path: "/users/observers",
        name: "observers",
        component: () => import("@/views/dashboard/users/Obervers.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
          breadcrumb: {
            label: "حسابات الإدارة العليا",
            parent: "users",
          },
        },
      },
      {
        path: "/users/governates",
        name: "governates",
        component: () => import("@/views/dashboard/users/Governates.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
          breadcrumb: {
            label: "المحافظات",
            parent: "users",
          },
        },
      },
      {
        path: "/governates/:governate/managements",
        name: "managers",
        component: () => import("@/views/dashboard/users/Managers.vue"),
        meta: {
          permissions: ["think", "superadmin", "governer"],
          exclude: [],
          breadcrumb() {
            const { params } = this.$route;

            const { label } = store.getters["users/governates"].find(
              (g) => g.id == params.governate
            );

            return {
              label: `إدارات محافظة ${label}`,
              parent: "governates",
            };
          },
        },
      },
      {
        path: "/:governate/managements/:management_id/schools",
        name: "schools",
        component: () => import("@/views/dashboard/users/Schools.vue"),
        meta: {
          permissions: ["think", "superadmin", "admin", "governer"],
          exclude: [],
          breadcrumb() {
            const { params } = this.$route;

            const { managements } = store.getters["users/governates"].find(
              (g) => g.id == params.governate
            );

            const { label } = managements.find(
              (m) => m.id == params.management_id
            );

            return {
              label: `معاهد ${label}`,
              parent: "managers",
            };
          },
        },
      },
      {
        path: "/list-barcodes",
        name: "list-barcodes",
        component: () => import("@/views/dashboard/ListBarcodes.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
        },
      },
      {
        path: "/list-schools",
        name: "list-schools",
        component: () => import("@/views/dashboard/Schools.vue"),
        meta: {
          permissions: ["think", "superadmin"],
          exclude: [],
        },
      },
      {
        path: "/requisitions",
        name: "requisitions",
        component: () => import("@/views/dashboard/Requisitions.vue"),
        meta: {
          permissions: ["think", "superadmin", "manufacturer"],
          exclude: [],
          breadcrumb: {
            label: "طلبات الشحن",
          },
        },
      },
      {
        path: "/requisitions/:requisition_id",
        name: "withdraw-orders",
        component: () => import("@/views/dashboard/WithdrawOrders.vue"),
        meta: {
          permissions: ["think", "superadmin", "manufacturer"],
          exclude: [],
          breadcrumb() {
            const {
              params: { id },
            } = this.$route;

            return {
              label: `أومر الشحن`,
              parent: "requisitions",
            };
          },
        },
      },
      {
        path: "/requisitions/:requisition_id/order/:id/barcodes",
        name: "withdraw-order-codes",
        component: () => import("@/views/dashboard/Barcodes.vue"),
        meta: {
          permissions: ["think", "superadmin", "manufacturer"],
          exclude: [],
          breadcrumb: {
            label: "قائمة أكواد أمر شحن",
            parent: "withdraw-orders",
          },
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

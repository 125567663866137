import axios from "./axios-auth";

const state = {
  governates: [],
  distributors: [],
  manufacturers: [],
};

const getters = {
  governates(state) {
    return state.governates;
  },
  distributors(state) {
    return state.distributors;
  },
  manufacturers(state) {
    return state.manufacturers;
  },
};

const actions = {
  fetchGovernates({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootGetters.API_URL}/auth/governates`).then((response) => {
        if (response.data.code == 200) {
          commit("SET_GOVERNATES", response.data.results);
        }

        resolve(response);
      });
    });
  },
  fetchGovernate({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/auth/governates/${payload}`)
        .then((response) => {
          resolve(response);
        });
    });
  },
  editGovernate({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/governates/edit`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  fetchSchools({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${rootGetters.API_URL}/auth/schools/list`;

      if (payload) {
        url += payload;
      }

      axios.get(url).then((response) => {
        resolve(response);
      });
    });
  },
  createManagement({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/management/create`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchGovernates");
          }

          resolve(response);
        });
    });
  },
  editManagement({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/management/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchGovernates");
          }

          resolve(response);
        });
    });
  },
  createSchool({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/school/create`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchGovernates");
          }

          resolve(response);
        });
    });
  },
  editSchool({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/school/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchGovernates");
          }

          resolve(response);
        });
    });
  },
  fetchObservers({ rootGetters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootGetters.API_URL}/auth/observers`).then((response) => {
        resolve(response);
      });
    });
  },
  createObserver({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/observer/create`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  editObserver({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/password-reset`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  fetchDistributors({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootGetters.API_URL}/auth/distributors`).then((response) => {
        if (response.data.code == 200) {
          commit("SET_DISTRIBUTORS", response.data.results);
        }

        resolve(response);
      });
    });
  },
  editDistributor({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/distributors/edit`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  createDistributor({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/distributors/create`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  fetchManufacturers({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/auth/manufacturers`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_MANUFACTURERS", response.data.results);
          }

          resolve(response);
        });
    });
  },
  createManufacturer({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/register`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchManufacturers");
          }

          resolve(response);
        });
    });
  },
  editManufacturers({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/manufacturers/edit`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchManufacturers");
          }

          resolve(response);
        });
    });
  },
  createCard({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/qrcode/create`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
  toggleCardStatus({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/qrcode/${payload}/toggle`)
        .then((response) => {
          resolve(response);
        });
    });
  },
  removeCard({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API_URL}/auth/qrcode/${payload}/remove`)
        .then((response) => {
          resolve(response);
        });
    });
  },
};

const mutations = {
  SET_GOVERNATES: (state, payload) => {
    state.governates = payload;
  },
  SET_DISTRIBUTORS: (state, payload) => {
    state.distributors = payload;
  },
  SET_MANUFACTURERS: (state, payload) => {
    state.manufacturers = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

import axios from "axios";
import axioth from "./axios-auth";

import auth from "./auth";
import users from "./users";
import supply from "./supply";
import reports from "./reports";

export default new Vuex.Store({
  state: {
    API: "/api",
    isMenuOpen: true,
    totalStudents: 0,
    mealsDeliveredToday: 0,
    totalTransactionsToday: 0,
    deliveredPercent: 0,
    transactions: [],
    settings: null,
    factories: [],
  },
  getters: {
    API_URL(state) {
      return state.API;
    },
    settings(state) {
      return state.settings;
    },
    isMenuOpen(state) {
      return state.isMenuOpen;
    },
    totalStudents(state) {
      return state.totalStudents;
    },
    mealsDeliveredToday(state) {
      return state.mealsDeliveredToday;
    },
    totalTransactionsToday(state) {
      return state.totalTransactionsToday;
    },
    deliveredPercent(state) {
      return state.deliveredPercent;
    },
    transactions(state) {
      return state.transactions;
    },
    factories(state) {
      return state.factories;
    },
  },
  mutations: {
    SET_FACTORIES: (state, payload) => {
      state.factories = payload;
    },
    SET_APP_SETTINGS: (state, payload) => {
      state.settings = payload;
    },
    TOGGLE_SIDE_MENU: (state, payload) => {
      state.isMenuOpen = payload;
    },
    UPDATE_REALTIME_DATA: (state, payload) => {
      const {
        totalStudents,
        mealsDeliveredToday,
        totalTransactionsToday,
        transactions,
      } = payload;

      state.totalStudents = totalStudents
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      state.mealsDeliveredToday = mealsDeliveredToday
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      state.totalTransactionsToday = totalTransactionsToday
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      state.deliveredPercent =
        Math.round(mealsDeliveredToday / totalStudents) * 100;

      state.transactions = transactions;
    },
  },
  actions: {
    updateSettings({ rootGetters, commit }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .put(`${rootGetters.API_URL}/auth/settings`, payload)
          .then((response) => {
            if (response.data.code == 200) {
              commit("SET_APP_SETTINGS", payload);
            }

            resolve(response);
          });
      });
    },
    fetchNotifications({ rootGetters, commit }) {
      return new Promise((resolve, reject) => {
        axioth
          .get(`${rootGetters.API_URL}/stats/transactions`)
          .then((response) => {
            if (response.data.code == 200) {
              commit("UPDATE_REALTIME_DATA", response.data.results);
            }

            resolve(response);
          });
      });
    },
    fetchObserverStats({ rootGetters }) {
      return new Promise((resolve, reject) => {
        axioth.get(`${rootGetters.API_URL}/stats/observer`).then((response) => {
          resolve(response);
        });
      });
    },
    fetchTransactionData({ rootGetters }) {
      return new Promise((resolve, reject) => {
        axioth
          .get(`${rootGetters.API_URL}/transaction/basic-data`)
          .then((response) => {
            resolve(response);
          });
      });
    },
    fetchLoggedInUsers({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .get(`${rootGetters.API_URL}/auth/users?${payload}`)
          .then((response) => {
            resolve(response);
          });
      });
    },
    sendTransaction({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .post(`${rootGetters.API_URL}/transactions`, payload)
          .then((response) => {
            resolve(response);
          });
      });
    },
    updateBasicData({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .put(`${rootGetters.API_URL}/basic-data`, payload)
          .then((response) => {
            resolve(response);
          });
      });
    },
    updateItemData({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth.put(`${rootGetters.API_URL}/items`, payload).then((response) => {
          resolve(response);
        });
      });
    },
    checkBarcodeValidity({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .get(`${rootGetters.API_URL}/check-barcode/${payload}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    toggleSchoolOverSupply({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .put(`${rootGetters.API_URL}/schools/${payload}/supply`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    detchItems({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axioth
          .post(`${rootGetters.API_URL}/schools/attach`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: { auth, users, supply, reports },
  plugins: [vuexLocal.plugin],
});

import axios from "axios";
import auth from "./axios-auth";

function getURL({ type, governate_id }) {
  // superadmin | admin | school | observer
  switch (type) {
    case "admin":
      return "/management";
      break;

    case "school":
      return "/school";
      break;

    case "governer":
      return `/governates/${governate_id}/managements`;
      break;

    case "manufacturer":
      return `/requisitions`;
      break;

    case "observer":
      return `/delivery-details`;
      break;

    default:
      return "/dashboard";
      break;
  }
}

const state = {
  user: null,
  token: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.token && state.user;
  },
};

const actions = {
  login({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/auth/login`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_USER_SESSION", response.data.results);
            commit("SET_APP_SETTINGS", response.data.settings, { root: true });

            auth.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.results.token}`;

            const url = getURL(response.data.results);
            window.location.replace(url);

            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .post(`${rootGetters.API_URL}/auth/logout`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("UNSET_USER_SESSION");
            window.location.replace("/");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logoutUsers({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .post(`${rootGetters.API_URL}/auth/logout`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/auth/password-reset`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_USER_SESSION: (state, payload) => {
    const { token, ...user } = payload;

    state.token = token;

    state.user = user;

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  },
  UNSET_USER_SESSION: (state) => {
    state.token = null;
    state.user = null;

    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },
  UPDATE_USER: (state, payload) => {
    state.user = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import axios from "./axios-auth";

const state = {
  distributors: [],
  requisitions: [],
};

const getters = {
  distributors(state) {
    return state.distributors;
  },
  requisitions(state) {
    return state.requisitions;
  },
};

const actions = {
  fetchDistributors({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/distributors`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_DISTRIBUTORS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createRequisition({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/requisitions`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchRequisitions({ rootGetters, commit }, payload = "") {
    return new Promise((resolve, reject) => {
      let url = `${rootGetters.API_URL}/requisitions`;
      if (payload != "") url += `?${payload}`;

      axios
        .get(url)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_REQUISITIONS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOneRequisition({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/requisitions/${payload}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editRequisition({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/requisitions`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeRequisition({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API_URL}/requisitions/${payload}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createShipment({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/orders`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateShipment({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/orders`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeShipmentItem({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API_URL}/orders/${payload}/detail`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeShipment({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API_URL}/orders/${payload.id}`, {
          data: {
            details: payload.details,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateQRs({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/orders/${payload}/generate-codes`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOrderBarcodes({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/barcodes/${payload.id}?${payload.query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBracodeTimeline({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API_URL}/barcode/${payload}/timeline`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  closeRequision({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/requisitions/${payload}/close`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  blockBarcodes({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API_URL}/barcodes/close`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markAsPrinted({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API_URL}/barcodes/printed`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_DISTRIBUTORS: (state, payload) => {
    state.distributors = payload;
  },
  SET_REQUISITIONS: (state, payload) => {
    state.requisitions = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default {
  "deliverd-now-icon": () => import("vue-material-design-icons/TruckCheck.vue"),
  "speedometer-icon": () => import("vue-material-design-icons/Speedometer.vue"),
  "box-icon": () => import("vue-material-design-icons/PackageVariant.vue"),
  "warehouse-icon": () => import("vue-material-design-icons/Warehouse.vue"),
  "password-icon": () => import("vue-material-design-icons/Lock.vue"),
  "load-icon": () => import("@/components/LoadingIcon.vue"),
  "back-icon": () => import("vue-material-design-icons/ArrowLeft"),
  "sub-icon": () => import("vue-material-design-icons/AccountSupervisor"),
  "truck-icon": () => import("vue-material-design-icons/Truck"),
  "printer-icon": () => import("vue-material-design-icons/Printer"),
  "barcode-icon": () => import("vue-material-design-icons/Barcode"),
  "timeline-icon": () => import("vue-material-design-icons/Timeline"),
  "view-icon": () => import("vue-material-design-icons/Eye"),
  "close-icon": () => import("vue-material-design-icons/Close"),
  "cancel-icon": () => import("vue-material-design-icons/CloseCircle"),
  "menu-icon": () => import("vue-material-design-icons/Menu"),
  "charts-icon": () => import("vue-material-design-icons/ChartPie"),
  "history-icon": () => import("vue-material-design-icons/ClipboardTextClock"),
  "logout-icon": () => import("vue-material-design-icons/Logout"),
  "collapse-icon": () => import("vue-material-design-icons/ArrowCollapse"),
  "expand-icon": () => import("vue-material-design-icons/ArrowExpand"),
  "detail-icon": () => import("vue-material-design-icons/Cookie"),
  "users-icon": () => import("vue-material-design-icons/AccountGroup"),
  "user-icon": () => import("vue-material-design-icons/Account"),
  "profile-icon": () => import("vue-material-design-icons/Account"),
  "domain-icon": () => import("vue-material-design-icons/Domain"),
  "school-icon": () => import("vue-material-design-icons/School"),
  "observer-icon": () => import("vue-material-design-icons/AccountTie"),
  "plus-icon": () => import("vue-material-design-icons/PlusBox"),
  "minus-icon": () => import("vue-material-design-icons/MinusBox"),
  "management-icon": () => import("vue-material-design-icons/OfficeBuilding"),
  "school-icon": () => import("vue-material-design-icons/School"),
  "edit-icon": () => import("vue-material-design-icons/Pencil"),
  "bell-icon": () => import("vue-material-design-icons/Bell"),
  "cog-icon": () => import("vue-material-design-icons/Cog"),
  "next-icon": () => import("vue-material-design-icons/ChevronLeft"),
  "prev-icon": () => import("vue-material-design-icons/ChevronRight"),
  "chevron-down-icon": () => import("vue-material-design-icons/ChevronDown"),
  "chevron-up-icon": () => import("vue-material-design-icons/ChevronUp"),
  "clip-icon": () => import("vue-material-design-icons/ClipboardOutline"),
  "barcode-icon": () => import("vue-material-design-icons/BarcodeScan"),
  "printer-icon": () => import("vue-material-design-icons/Printer"),
  "factory-icon": () => import("vue-material-design-icons/Factory"),
  "restore-icon": () => import("vue-material-design-icons/History"),
  "cards-icon": () => import("vue-material-design-icons/Cards"),
  "remove-icon": () => import("vue-material-design-icons/Delete"),
  "marker-icon": () => import("vue-material-design-icons/MapMarker"),
  "withdraw-icon": () =>
    import("vue-material-design-icons/FileDocumentMultipleOutline"),
};

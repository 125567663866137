import axios from "./axios-auth";

function getStockSum(field, stock) {
  let sum = [...stock]
    .flatMap((s) => s[1])
    .map((s) => parseInt(s[field]))
    .reduce((prev, acc) => prev + acc);

  return sum;
}

function mapStock(stock) {
  let governatesStock = [...stock];

  governatesStock = [...governatesStock].map((gov) => {
    let [label, items] = gov;

    items = [...items].map((item) => {
      let { Total_IN, Total_Out, OpenningBalance, ...rest } = item;

      let balance = Total_IN - Total_Out;

      return {
        ...rest,
        OpenningBalance,
        Total_IN: parseInt(Total_IN),
        Total_Out,
        balance,
        Closing_Balance: balance + OpenningBalance,
      };
    });

    return [label, items];
  });

  return governatesStock;
}

const state = {
  stockByGovernate: [],
  stock: [],
  summary: {
    schools_count: 0,
    student_count: 0,
    disabled_cards: 0,
    disabled_locations: 0,
    unavailable_barcodes: 0,
    delivered_students: 0,
  },
};

const getters = {
  stockBalances(state) {
    return state.stockByGovernate;
  },
  stock() {
    return state.stock;
  },
  summary() {
    return state.summary;
  },
};

const actions = {
  fetchStockByGovernate({ rootGetters, commit }, query) {
    return new Promise((resolve, reject) => {
      let stockURL = `${rootGetters.API_URL}/reports/balances`;
      let summaryURL = `${rootGetters.API_URL}/reports/summary`;

      if (query) {
        stockURL += `?${query}`;
        summaryURL += `?${query}`;
      }
      const promises = [axios.get(stockURL), axios.get(summaryURL)];

      Promise.all(promises)
        .then((response) => {
          if (response.every((req) => req.status == 200)) {
            const [stockResponse, summaryResponse] = response;

            const { results: stock } = stockResponse.data;
            const { results: summary } = summaryResponse.data;

            commit("SET_STOCK", stock);
            commit("SET_SUMMARY", summary);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_STOCK: (state, payload) => {
    state.stock = mapStock(payload);

    state.stockByGovernate = payload;
  },
  SET_SUMMARY: (state, payload) => {
    state.summary = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

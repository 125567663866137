// socket.io
import { io } from "socket.io-client";

const ENV = {
  dev: "ws://127.0.0.1:5000",
  test: "wss://azhar-test.menugram.net",
  stage: "wss://test.menugram.net",
  prod: "wss://azhar.menugram.net",
};

// PRODUCTION
const socket = io(ENV["prod"], {
  transports: ["websocket"],
  autoConnect: false,
  query: {
    token: localStorage.getItem("token"),
  },
});

socket.on("connect", () => {
  console.log("connected to socket!!!");
});

export default socket;
